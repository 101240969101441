<template>
	<v-container>
		<v-row>
			<v-col>
				<h2>{{opporutinity_type}}</h2>
			</v-col>
		</v-row>
		<v-row v-if="loading">
			<v-col cols="6">
				<v-progress-linear indeterminate color="blue"></v-progress-linear>
			</v-col>
		</v-row>
		<v-row v-if="!loading">
			<v-col md="6" cols="12">
				<v-card
				outlined
				:loading="loading"
				>

			<v-simple-table>
				<template v-slot:default>
				<tbody>
					<tr>
						<td>Activity</td>
						<td>
							<ul class="py-4">
								<li v-for="activity in online_data.activities" :key="activity.name_en">
									{{activity.name_en}}
								</li>
							</ul>         
						</td>
					</tr>
					<tr>
						<td>Opporutinity Type</td>
						<td>{{online_data.type}}</td>
					</tr>
					<tr>
						<td>Previous Business</td>
						<td>{{online_data.name_en}}</td>
					</tr>
					<tr>
						<td>Previous Business Age</td>
						<td>{{online_data.branch_age_year}} Year(s)</td>
					</tr>
					<tr>
						<td>Previous Business Occupation</td>
						<td>{{online_data.occupation_period_year}} Year(s)</td>
					</tr>
					<tr>
						<td>Registration date</td>
						<td>{{online_data.branch_reg_date}}</td>
					</tr>
					<tr>
						<td>Expire date</td>
						<td>{{online_data.branch_exp_date}}</td>
					</tr>
					<tr>
						<td>Address</td>
						<td>
							<ul class="address_padding">
							<li>
								Office: {{online_data.shop_no}}
							</li>
							<li>
								Building: {{online_data.building}}
							</li>
							<li>
								Road: {{online_data.road}}
							</li>
							<li>
								Block: {{online_data.block}}
							</li>
							<li>
								Area: {{online_data.town}}
							</li>

							</ul>
						</td>
					</tr>
					<tr>
						<td>Search Distense</td>
						<td>{{online_data.nearby_distance}} Meters</td>
					</tr>
					<tr>
						<td>Number of Business around</td>
						<td>{{online_data.nearby_branch_count}}</td>
					</tr>

				</tbody>
			</template>
		</v-simple-table>
	</v-card>

	</v-col>
	<v-col md="6" cols="12">
		<v-card outlined v-show="online_data.lat" :loading="loading">
		<div class="map" ref='map' ></div>
	</v-card>
</v-col>
</v-row>
<v-row v-if="!loading">
	<v-col cols="12" md="6">
		<h2>Nearby Activity within {{online_data.nearby_distance}} Meters</h2>
		<v-card outlined :loading="loading">
			<v-data-table
			:headers="nearby_activity_header"
			:items="online_data.nearby_activities"
			:items-per-page="5"
			class="elevation-1"
			>
			<template v-slot:item.name_en="{ item }">
				<p style="margin-top:16px">
					{{ item.name_en }}
				</p>
			</template>
			
		</v-data-table>
	</v-card>
	
</v-col>
</v-row>
</v-container>
</template>
<script>
// @ is an alias to /src
import {load_opportunity_details} from '@/api/app'
//import business_json from '@/data/business_activity_list.json'
export default {
	name: 'OpporutinityDetails',
	data:function(){
		return {
			opporutinity_type:'Opportunity Details',
			error:false,
			error_message:'',
			loading:false,
			nearby_activity_header: [
			{ text: 'Activity', value: 'name_en' ,},
			{ text: 'Count', value: 'count' ,width:100,align:'center'},
			],
			online_data:{
				cr_branch_number: "",
				address_change_in_history: false,
				amendment_date: "",
				occupation_period_year: 0,
				branch_age_year: 0,
				nearby_branch_count: 0,
				nearby_activities: [
				],
				block: 0,
				lat: 0,
				lon: 0,
				shop_no: "0",
				building: "0",
				road: "0",
				town: "",
				name_en: "",
				name_ar: "",
				activities: [],
				branch_reg_date: "",
				branch_exp_date: "",
				created_at: "",
				object_id: "",
				nearby_distance: 0,
				new: false,
				type: ""
				
			},
			//business_activity_list:business_json,
			
		}
	},
	methods:{
		fill_data: async function() {
			if (this.online_data.branch_age_year <3){ 
				this.opporutinity_type="Unfortunate Attempt Details"
			}
			console.log("fill map data");
			if (this.online_data.lat != null && this.online_data.lon != null){
			let {
				Maps
			} = await this.getMap()

			var navigationBarMode = Maps.NavigationBarMode;
			//Maps.Location(26.208386, 50.538245)
			let map =new Maps.Map(this.$refs.map, {
				navigationBarMode: navigationBarMode.square,
				center: new Maps.Location(this.online_data.lat,this.online_data.lon),
				zoom:15,
				mapTypeId: Maps.MapTypeId.canvasLight,
        supportedMapTypes: [Maps.MapTypeId.road, Maps.MapTypeId.aerial, Maps.MapTypeId.grayscale,Maps.MapTypeId.canvasLight]
			})

			let pin_location=new Maps.Location(this.online_data.lat,this.online_data.lon);
			Maps.loadModule(['Microsoft.Maps.SpatialMath', 'Microsoft.Maps.Contour'], function () {

				
				let locs = Maps.SpatialMath.getRegularPolygon(pin_location, 250, 36, Maps.SpatialMath.DistanceUnits.Meters);

				let circle1=new Maps.ContourLine(locs, 'rgba(0,150,200,0.5)');
				var layer = new Maps.ContourLayer([circle1], {
					colorCallback: function (val) {
											//console.log("Color clicked",val);
											return val;
										},
										polygonOptions: {
											strokeThickness: 0
										}
									});
				map.layers.insert(layer);
			});
			var pushpin = new Maps.Pushpin(pin_location,{color: '#2c3e50'});

			map.entities.push(pushpin);

			//console.log('map: ',map)
			// //https://www.bing.com/api/maps/sdk/mapcontrol/isdk/adddefaultpushpin#TS
			
		}
	},
	get_data:function(){

		this.loading=true;

		// update data
		let object_id =this.$route.params.objectId;
		// let object_id ="ffbddb02-a30a-4c70-b2f0-a97c60c919a2";
		
		load_opportunity_details({object_id}).then((response)=>{
			
			this.online_data=response.data;
					//this.chart_data=result_data;
					console.log('online data',this.online_data);
					if (response.error ==false){
						this.fill_data()
					}
					else{
						// show error 
						this.error=true;
						this.error_message=response.data.error_message;
					}
					this.loading=false;
				})
	},
	getMap() {
		let script = document.createElement('script')
		script.src = 'https://www.bing.com/api/maps/mapcontrol?key=AgzeobkGvmpdZTFuGa7_6gkaHH7CXHKsFiTQlBvi55x-QLZLh1rSjhd1Da9bfPhD&callback=getMap';
		document.head.append(script)
		return new Promise(resolve => {
			window.getMap = function() {
				resolve(window.Microsoft)
			}
		})
	},
},

async mounted () {
		//console.log(this.$route.params.objectId);
		
		

		this.get_data();

	}
}
</script>

<style scoped>
td:first-child {
	width:35%;
}
table th + th { border-left:1px solid #dddddd; }
table td + td { border-left:1px solid #dddddd; }
.address_padding {
	padding:15px;
}


.map{
	width:100%;
	height: 565px;
}
@media only screen and (max-width: 768px) {
	.map{
		height: 350px;
	}
}
</style>