<template>
	<v-container>
		<v-row class="mb-4">
			<v-col>
				<h2>Market</h2>
			</v-col>
			<v-spacer></v-spacer>
			<v-col class="text-right">
				<v-btn
				color="teal white--text"
				@click="show_add_new_market"
				>
					Add new market
				</v-btn>
			</v-col>
		</v-row>
		<v-card
		class="pa-4 mb-4"
		outlined
		v-if="show_add_new_market_block"
		>
		<v-card-title>
			Add new market
		</v-card-title>
		<v-card-text>
			<v-form
				ref="add_market_form"
				>
			<v-row>
				
					<v-col cols="12" md="3">
						<v-text-field
						v-model="market_name"
						label="Market Name"
						outlined
						dense
						:error="error"
						:rules="market_name_rules"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="3">
						<v-select
						v-model="seleted_area"
						:items="bahrain_area"
						item-text="name_en"
						item-value="object_id"
						label="Select Area"
						dense
						outlined
						:error="error"
						:rules="seleted_area_rules"
						></v-select>
					</v-col>
					<v-col cols="12" md="3">
						<v-autocomplete
						dense
						outlined
						v-model="selected_activity"
						:items="business_activity_list"
						label="Select Business Activity"
						item-text="name_en"
						item-value="object_id"
						clearable
						:error="error"
						:rules="selected_activity_rules"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="3">
						<v-btn color="teal white--text"  @click="add_new_market" :loading="loading">Save</v-btn>
					</v-col>

				</v-row>
			</v-form>
			</v-card-text>
		</v-card>

		<v-card
		class="pa-4"
		outlined>

		<template>
			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">
								Market Name
							</th>
							<th class="text-left">
								Activity
							</th>
							<th class="text-left">
								Area
							</th>
							<th class="text-left">
								Action
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="item in market_list"
							:key="item.object_id"
							>
							<td>{{ item.name }}</td>
							<td>{{ get_activity(item.activity_object_id) }}</td>
							<td>{{ get_area(item.area_object_id) }}</td>
							<td>
								<v-btn
								outlined
								class="ma-2"
								small
								:disabled="item.default"
								@click="set_default_market(item.object_id)"
								>
								Default
								</v-btn>
								<v-btn
								class="ma-2"
								outlined
								color="red"
								small
								:disabled="disable_delete"
								@click="delete_market(item.object_id)"
								>
								Delete
								</v-btn>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</template>

		</v-card>
		
		<v-snackbar
			v-model="error_snackbar"
			bottom
			color="error"
			outlined
			right>
			{{ snackbar_error_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="pink"
				text

				v-bind="attrs"
				@click="error_snackbar = false"
				>
				Close
			</v-btn>
		</template>
		</v-snackbar>


		<v-snackbar
			:timeout="2000"
			
			bottom
			color="success"
			outlined
			right
			v-model="success_snakbar">
			{{ snackbar_success_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="teal"
				text

				v-bind="attrs"
				@click="success_snakbar = false"
				>
				Close
			</v-btn>
		</template>
	</v-snackbar>

	</v-container>
</template>
<script>
import business_json from '@/data/business_activity_list.json'
import bahrain_area_block from '@/data/bahrain_area_block.json'
import { add_market,delete_market} from '@/api/app'
export default {
	name: 'Market',
	data:function (){
		return{
			disable_delete:false,
			seleted_area_rules:[v => !!v || 'Area is required',],
			selected_activity_rules:[v => !!v || 'Activity is required',],
			error:false,
			market_name_rules:[
				v => !!v || 'Market name is required',
				v => v.trim().length<30 || 'Market name is too long',
				v => v.trim().length>2 || 'Market name is too short',
				],
			
			loading:false,
			business_activity_list:business_json,
			bahrain_area:bahrain_area_block,
			market_name:'',
			seleted_area:'',
			selected_activity:'',
			show_add_new_market_block:false,
			error_snackbar: false,
			success_snakbar:false,
			snackbar_error_message:"",
			snackbar_success_message:"",

		}
	},
	methods:{
		show_add_new_market(){
			this.show_add_new_market_block=!this.show_add_new_market_block
		},
		add_new_market(){
			// validate

			if (this.$refs.add_market_form.validate()==false){
				console.log("error in form");
				this.snackbar_error_message="Invalid new market data";
				this.error_snackbar=true;

				return 
			}
			// save the data online first
			this.loading=true;
			let market={
			"name":this.market_name,
			"area_object_id":this.seleted_area,
			"activity_object_id":this.selected_activity,
			
			}
			add_market(market).then(response => {
				this.loading=false;
				if (response.error==false){
					response.data.default=false;
					this.$store.commit('add_market',response.data)
					this.snackbar_success_message=this.market_name +" is added";
					this.success_snakbar=true;
					this.disable_delete=false;
				}else{
					//show error
					this.snackbar_error_message=response.error_message;
					this.error_snackbar=true;

				}
			})

		},
		get_activity(activity_id){
			for (let i=0;i<this.business_activity_list.length;i++){
				if (this.business_activity_list[i].object_id==activity_id){
					return this.business_activity_list[i].name_en;
				}

			}
			return "Activity name not found!";
		},
		get_area(area_object_id){
			for (let i=0;i<this.bahrain_area.length;i++){
				if (this.bahrain_area[i].object_id==area_object_id){
					return this.bahrain_area[i].name_en;
				}

			}
			return "Area name not found!";
		},
		set_default_market(object_id){
			this.$store.commit("set_default_market",object_id);
		},
		delete_market(object_id){
			if (this.market_list.length==1){
				this.error_snackbar=true;
				this.snackbar_error_message="Can't delete last market";
				this.disable_delete=true;
				return 
			}

			delete_market({object_id}).then(response=>{
				console.log("response")
				if (response.error == false){
					// delete local market
					this.$store.commit("delete_market",object_id);
					if (this.market_list.length==1){
						this.disable_delete=true;
					}

				}else{
					this.snackbar_message=response.error_message;
					this.show_snackbar=true;
				}
			});
		},
		set_default(){
			let found_default=false;
			for (let i=0;i<this.market_list.length;i++){

				if (this.market_list[i].default){
					found_default=true;
					break;
				}
			}
			if (found_default==false){
				// set default to zero
				this.market_list[0].default=true;
			}
		}

	},
	computed: {
		market_list(){
			return this.$store.getters.market_list
		},

	},

	mounted(){
		if (this.market_list.length==1){
			this.disable_delete=true;
		}
		// set default in case not set 
		this.set_default();
	}

}
</script>